
import { Vue, Component, Prop } from 'vue-property-decorator'

import $app from '@/plugins/modules'
import { Common } from '@/common'
import { System } from '@/modules/system'
import { Access } from '@/common/enums';
import { ActionStyle, Size } from '@/modules/system/types';

@Component
export default class BaseAction extends Vue {
  @Prop({ default: undefined })
  readonly access: Access | undefined;

  @Prop(String)
  readonly caption: string | undefined;

  @Prop(String)
  readonly icon: string | undefined;

  @Prop([String])
  readonly color: string | undefined;

  @Prop({ default: false })
  readonly dark!: boolean | string;

  @Prop({ default: false })
  readonly disabled!: boolean | string;

  @Prop({ default: 'normal' })
  readonly size!: Size;

  @Prop({ default: 'flat' })
  readonly type!: ActionStyle;

  @Prop({ default: false })
  readonly loading!: boolean | string;

  @Prop({ default: false })
  readonly rounded!: boolean | string;

  common = $app.module(Common);
  system = $app.module(System);

  get enabled() {
    return !this.disabled && this.disabled !== '' && $app.access(this.access || Access.Any);
  }

  doClick(e: any) {
    this.$emit('click', e);
  }
}
